import Cookies from 'js-cookie';

import { ajaxPromise, displayNotification, dtColumnIndicies, getDateFilter, getDtRowData, highlight, htmlEsc, initDateFilter, logerror, logme, post, setDateFilter, setTimeoutPromise, toggleSlide } from './utils';
import { buildCharts, contactsTableBuild, contractsTableBuild, loadConcentrationMap, printThis } from './main';
import { ASSESSMENT_CONCHECKLIST, CONTRACT_TYPE_STANDARD, DATATABLE_ALL, THRESHOLD_INHERENT, THRESHOLD_KPI, THRESHOLD_RESIDUAL, THRESHOLD_SLA, THRESHOLD_VENDORVALUE, VENDOR_ACTIVE, VENDOR_PENDING, WATCH_VENDOR_ACTIVE } from './constants';

const dashboardCompletion = async () => {
	try {
		const res = await ajaxPromise('/data/dashboard_get');
		if (res.rc !== 'OK') throw res;
		if ($('#vendor_completion_table').length == 1) {
			$('#vendor_completion_table tr').each((i, tr) => {
				$(tr).find('td').first().html(res.counts.vendor[i]);
			});
		}
		if ($('#components_completion_table').length == 1) {
			$('#components_completion_table tr').each((i, tr) => {
				const $cell = $(tr).find('td').first();
				$cell.html(res.counts.incompletes[i]);
				if (+res.counts.incompletes[i] > 0) {
					$cell.addClass('red').removeClass('green');
				} else {
					$cell.addClass('green').removeClass('red');
				}
			});
		}
		if ($('#review_completion_table').length == 1) {
			$('#review_completion_table tr').each((i, tr) => {
				$(tr).find('td').first().html(res.counts.review[i]);
			});
		}
		if ($('#contract_completion_table').length == 1) {
			$('#contract_completion_table tr').each((i, tr) => {
				$(tr).find('td').first().html(res.counts.contract[i]);
			});
		}
		if ($('#documents_completion_table').length == 1) {
			$('#documents_completion_table tr').each((i, tr) => {
				$(tr).find('td').first().html(res.counts.documents[i]);
			});
		}
		if ($('#tasks_completion_table').length == 1) {
			$('#tasks_completion_table tr').each((_, tr) => {
				const $tr = $(tr);
				const $td = $tr.find('td').first();
				const $a = $tr.find('a').first();
				const status = $a.data('filter');
				$td.html(res.counts.tasks[status]);
			});
		}
		if ($('#watch_completion_table').length == 1) {
			$('#watch_completion_table tr').each((_, tr) => {
				const $tr = $(tr);
				const $td = $tr.find('td').first();
				const $a = $tr.find('a').first();
				const status = $a.data('filter');
				$td.html(res.counts.watch[status]);
			});
		}
		if ($('#onboarding_completion_table').length == 1) {
			$('#onboarding_completion_table tr').each((index, tr) => {
				const $td = $(tr).find('td').first();
				$td.html(res.counts.onboarding[index]);
			});
		}
		if ($('#offboarding_completion_table').length == 1) {
			$('#offboarding_completion_table tr').each((index, tr) => {
				const $td = $(tr).find('td').first();
				$td.html(res.counts.offboarding[index]);
			});
		}
		if ($('#contract_checklists_completion_table').length == 1) {
			$('#contract_checklists_completion_table tr').each((index, tr) => {
				const $td = $(tr).find('td').first();
				$td.html(res.counts.contract_checklists[index]);
			});
		}
	} catch (error) {
		displayNotification('Dashboard Load', 'There was an error loading the dashboard.', 'danger');
		logerror('dashboard get', error);
	}
};

if ($('#dashboard_vendor_completion').length == 1) dashboardCompletion();

let dashboardVendorDt: DataTables.Api = null;
let dashboardContractsDt: DataTables.Api = null;
let dashboardDocumentsDt: DataTables.Api = null;

$('#programstatus_print').on('click', async ({target}) => {
	const btnId = '#' + $(target).attr('id'); // get the calling print button
	const config = {
		title: 'Program Status',
		callback: () => {
			const $body = $('#dashboard_container .panel-body').clone();
			$body.find(btnId).remove(); // remove the calling print button
			$body.append($('#copyright_footer').clone().css('text-align', 'center')); //add the footer
			return $body;
		},
	};
	printThis(config);

	try {
		await ajaxPromise('/form/submit', { type: 'program_status_print' });
	} catch (error) {
		logerror('program status print', error);
	}
});

$('#checklists_print').on('click', async ({target}) => {
	const btnId = '#' + $(target).attr('id'); // get the calling print button
	const config = {
		title: 'Program Status',
		callback: () => {
			const $body = $('#checklists_container .panel-body').clone();
			$body.find(btnId).remove(); // remove the calling print button
			$body.append($('#copyright_footer').clone().css('text-align', 'center')); //add the footer
			return $body;
		},
	};
	printThis(config);

	try {
		await ajaxPromise('/form/submit', { type: 'vendor_checklists_print' });
	} catch (error) {
		logerror('vendor checklists print', error);
	}
});

$('#enterpriseoverview_programstatus_print').on('click', () => {
	const charts = overviewdt.ajax.json().charts;
	$('#enterpriseoverview_print').prop('disabled', true);

	const postData = {
		program_status: $('#dashboard_vendor_completion').html(),
		charts: JSON.stringify(charts),
	};
	post('/printout/enterprise', postData, '_blank');

	setTimeout(() => $('#enterpriseoverview_print').prop('disabled', false), 1000);
});

$('#enterpriseoverview_print').on('click', () => {
	const charts = overviewdt.ajax.json().charts;
	$('#enterpriseoverview_print').prop('disabled', true);

	const postData = {
		charts: JSON.stringify(charts),
	};
	post('/printout/enterprise', postData, '_blank');

	setTimeout(() => $('#enterpriseoverview_print').prop('disabled', false), 1000);
});

$('#con_enterprise_print').on('click', () => {
	const charts = overviewdt.ajax.json().charts;
	$('#con_enterprise_print').prop('disabled', true);

	const postData = {
		charts: JSON.stringify(charts),
	};
	post('/printout/contract_enterprise', postData, '_blank');

	setTimeout(() => $('#con_enterprise_print').prop('disabled', false), 1000);
});

if ($('#dashboard_vendor_table').length == 1) {
	init_dashboard_vendor();
}

function init_dashboard_vendor() {
	// Vendor

	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/dashboard_vendors_load',
			data: (postData) => ({
				...postData,
				filters: {
					nextreview: $('#dashboard_vendor_field_nextreview').val(),
				},
			}),
		},
		columns: [
			// Vendor, Inherent Risk, Last Review, Next Review, Days to Next Review
			{
				title: 'Vendor',
				data: 'vend_name_link',
				render: null,
			},
			{
				title: 'Inherent Risk',
				data: 'inherent_title',
			},
			{
				title: 'Last Review',
				data: 'nice_vend_lastreview',
			},
			{
				title: 'Next Review',
				data: 'nice_vend_nextreview',
			},
			{
				title: 'Days to Next Review',
				data: 'daystonextreview',
				className: 'text-right',
			},
		],
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) }
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> vendors",
			infoEmpty: 'No vendors to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total vendors)",
			lengthMenu: 'Show _MENU_ vendors',
		},
		order: [
			[4, 'asc'],
			[0, 'asc'],
		],
	};

	dashboardVendorDt = $('#dashboard_vendor_table')
		.on('init.dt, draw.dt', function () {
			$(this).find('.vendor_edit').off('click');
			$(this)
				.find('.vendor_edit')
				.on('click', function () {
					var data = JSON.parse(JSON.stringify(dashboardVendorDt.row($(this).closest('tr')).data()));
					post('/ui/vendor_edit', { id: data.vend_id });
				});
		})
		.DataTable(dtoptions);

	$('#dashboard_vendor_field_nextreview').off('change');
	$('#dashboard_vendor_field_nextreview').on('change', function () {
		setTimeout(function () {
			dashboardVendorDt.ajax.reload();
		}, 500);
	});
}

const initDashboardContract = () => {
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/dashboard_contracts_load',
			data: (postData) => ({
				...postData,
				filters: {
					canceldate: $('#dashboard_services_field_canceldate').val(),
				},
			}),
		},
		columns: [
			{
				title: 'Vendor',
				data: 'vend_name_link',
				render: null,
			},
			{
				title: 'Contract Title',
				data: 'contract_title_link',
				render: null,
			},
			{
				title: 'Contract #',
				data: 'contract_refnum',
			},
			{
				title: 'Risk',
				data: 'contract_risk',
			},
			{
				title: 'Autorenew',
				data: 'contract_autorenew',
				searchable: false,
			},
			{
				title: 'Days to Cancel / Renew',
				data: 'contract_dayscancel', // contract_daystocancel is how many days to offset termination date
				searchable: false,
			},
			{
				title: 'Cancel / Renew Date',
				data: 'contract_canceldate_nice',
			},
			{
				title: 'Days to Termination',
				data: 'contract_daysend',
				searchable: false,
			},
			{
				title: 'Termination Date',
				data: 'contract_enddate_nice',
			},
		],
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) },
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> contracts",
			infoEmpty: 'No contracts to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total contracts)",
			lengthMenu: 'Show _MENU_ contracts',
		},
		order: [
			[0, 'asc'],
			[1, 'asc'],
		],
	};

	dashboardContractsDt = $('#dashboard_contracts_table')
		.on('init.dt, draw.dt', ({target}) => {
			const $table = $(target);
			$table.find('.vendor_edit').off('click').on('click', ({target}) => {
				const contract = getDtRowData(dashboardContractsDt, target);
				post('/ui/vendor_edit', { id: contract.vend_id });
			});
			$table.find('.contract_edit').off('click').on('click', ({target}) => {
				const contract = getDtRowData(dashboardContractsDt, target);
				post('/ui/contract', {
					vend_id: contract.vend_id,
					contract_guid: contract.contract_guid,
				});
			});
		})
		.DataTable(dtOptions);

	$('#dashboard_services_field_canceldate').off('change').on('change', () => {
		setTimeout(() => dashboardContractsDt.ajax.reload(), 500);
	});
};

if ($('#dashboard_contracts_table').length) initDashboardContract();

if ($('#dashboard_documents_table').length == 1) {
	init_dashboard_document();
}

function init_dashboard_document() {
	// Document
	//vendor, doc type, last entered, next due, days to

	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/dashboard_documents_load',
			data: (postData) => ({
				...postData,
				filters: {
					nextdate: $('#dashboard_documents_field_nextdate').val(),
				},
			}),
		},
		columns: [
			{
				title: 'Vendor',
				data: 'vend_name_link',
				render: null,
			},
			{
				title: 'Document Type',
				data: 'doct_name',
			},
			{
				title: 'Last Entered',
				data: 'vreqdoc_lastdate_nice',
			},
			{
				title: 'Next Due',
				data: 'vreqdoc_nextdate_nice',
			},
			{
				title: 'Days to Next Due Date',
				data: 'vreqdoc_nextduedate',
			},
		],
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) }
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> documents",
			infoEmpty: 'No documents to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total documents)",
			lengthMenu: 'Show _MENU_ documents',
		},
		order: [
			[4, 'asc'],
			[0, 'asc'],
		],
	};

	dashboardDocumentsDt = $('#dashboard_documents_table')
		.on('init.dt, draw.dt', function () {
			$(this).find('.vendor_edit').off('click');
			$(this)
				.find('.vendor_edit')
				.on('click', function () {
					var data = JSON.parse(JSON.stringify(dashboardDocumentsDt.row($(this).closest('tr')).data()));
					post('/ui/vendor_edit', { id: data.vend_id });
				});
		})
		.DataTable(dtoptions);

	$('#dashboard_documents_field_nextdate').off('change');
	$('#dashboard_documents_field_nextdate').on('change', function () {
		setTimeout(function () {
			dashboardDocumentsDt.ajax.reload();
		}, 500);
	});
}

const initDashboardTasks = () => {
	const dbColumns: DataTables.ColumnSettings[] = [
		{ title: 'Vendor', data: 'vend_name_link', render: null },
		{ title: 'Action Item', data: 'ttype_name' },
		{ title: 'Recipients', data: 'recipients' },
		{ title: 'Due Date', data: 'tk_duedate_pretty' },
		{ title: 'Status', data: 'tk_status_pretty' },
		{ title: 'Actions', data: 'actions', className: 'text-right', orderable: false, searchable: false, render: null },
	];
	const columnIndicies = dtColumnIndicies(dbColumns);
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/dashboard_tasks_load',
			data: (postData) => ({
				...postData,
				filters: {
					status: $('#dashboard_tasks_field_status').val(),
				},
			}),
		},
		columns: dbColumns,
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) }
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> tasks",
			infoEmpty: 'No tasks to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total tasks)",
			lengthMenu: 'Show _MENU_ tasks',
		},
		order: [
			[columnIndicies.tk_status_pretty, 'ASC'],
			[columnIndicies.tk_duedate_pretty, 'ASC'],
		],
	};

	const dashboardTasksDt = $('#dashboard_tasks_table')
		.on('init.dt, draw.dt', ({target}) => {
			$(target).find('.vendor_edit').off('click').on('click', ({target}) => {
				post('/ui/vendor_edit', { id: target.dataset.vendid });
			});
		})
		.DataTable(dtOptions);

	$('#dashboard_tasks_field_status').off('change').on('change', () => {
		setTimeout(() => dashboardTasksDt.ajax.reload(), 500);
	});

	$('#dashboard_tasks_table').on('click', '.task_go', ({ target }) => {
		const row = getDtRowData(dashboardTasksDt, target);
		window.location.href = row.link;
	});
};

if ($('#dashboard_tasks_table').length == 1) initDashboardTasks();

const initDashboardOnboarding = () => {
	const dbColumns: DataTables.ColumnSettings[] = [
		{ title: 'Vendor', data: 'vend_name_link', render: null },
		{ title: 'Checklist', data: 'assess_link', render: null, orderable: false, searchable: false},
		{ title: 'Status', data: 'status_pretty', searchable: false },
		{ title: 'Reviewer', data: 'usr_name' },
		{ title: 'Date', data: 'surveyresp_date_pretty' },
	];
	const columnIndicies = dtColumnIndicies(dbColumns);
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/dashboard_onboarding_load',
			data: (postData) => ({
				...postData,
				filters: {
					status: $('#dashboard_onboarding_filter').val(),
				},
			}),
		},
		columns: dbColumns,
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) }
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> onboarding checklists",
			infoEmpty: 'No onboarding checklists to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total onboarding checklists)",
			lengthMenu: 'Show _MENU_ onboarding checklists',
		},
		order: [
			[columnIndicies.vend_name_link, 'ASC'],
		],
	};

	const dashboardOnboardingDt = $('#dashboard_onboarding_table')
		.on('init.dt, draw.dt', ({target}) => {
			$(target).find('.vendor_edit').off('click').on('click', ({target}) => {
				post('/ui/vendor_edit', { id: target.dataset.vendid });
			});
			$(target).find('.assessment').off('click').on('click', ({target}) => {
				post('/ui/vendor_edit', { id: target.dataset.vendid, start: 'onboarding' });
			});
		})
		.DataTable(dtOptions);

	$('#dashboard_onboarding_filter').off('change').on('change', () => {
		setTimeout(() => dashboardOnboardingDt.ajax.reload(), 500);
	});
};

if ($('#dashboard_onboarding_table').length == 1) initDashboardOnboarding();

const initDashboardOffboarding = () => {
	const dbColumns: DataTables.ColumnSettings[] = [
		{ title: 'Vendor', data: 'vend_name_link', render: null },
		{ title: 'Checklist', data: 'assess_link', render: null, orderable: false, searchable: false},
		{ title: 'Status', data: 'status_pretty', searchable: false },
		{ title: 'Reviewer', data: 'usr_name' },
		{ title: 'Date', data: 'surveyresp_date_pretty' },
	];
	const columnIndicies = dtColumnIndicies(dbColumns);
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/dashboard_offboarding_load',
			data: (postData) => ({
				...postData,
				filters: {
					status: $('#dashboard_offboarding_filter').val(),
				},
			}),
		},
		columns: dbColumns,
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) }
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> offboarding checklists",
			infoEmpty: 'No offboarding checklists to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total offboarding checklists)",
			lengthMenu: 'Show _MENU_ offboarding checklists',
		},
		order: [
			[columnIndicies.vend_name_link, 'ASC'],
		],
	};

	const dashboardOffboardingDt = $('#dashboard_offboarding_table')
		.on('init.dt, draw.dt', ({target}) => {
			$(target).find('.vendor_edit').off('click').on('click', ({target}) => {
				post('/ui/vendor_edit', { id: target.dataset.vendid });
			});
			$(target).find('.assessment').off('click').on('click', ({target}) => {
				post('/ui/vendor_edit', { id: target.dataset.vendid, start: 'offboarding' });
			});
		})
		.DataTable(dtOptions);

	$('#dashboard_offboarding_filter').off('change').on('change', () => {
		setTimeout(() => dashboardOffboardingDt.ajax.reload(), 500);
	});
};

if ($('#dashboard_offboarding_table').length == 1) initDashboardOffboarding();

const initDashboardContractChecklists = () => {
	const dbColumns: DataTables.ColumnSettings[] = [
		{ title: 'Vendor', data: 'vend_name_link', render: null },
		{ title: 'Contract', data: 'contract_title_link', render: null },
		{ title: 'Checklist', data: 'assess_link', render: null, searchable: false},
		{ title: 'Status', data: 'status_pretty', searchable: false },
		{ title: 'Reviewer', data: 'usr_name' },
		{ title: 'Date', data: 'surveyresp_date_pretty' },
	];
	const columnIndicies = dtColumnIndicies(dbColumns);
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/dashboard_contract_checklists_load',
			data: (postData) => ({
				...postData,
				filters: {
					status: $('#dashboard_contract_checklists_filter').val(),
				},
			}),
		},
		columns: dbColumns,
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) }
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> contract checklists",
			infoEmpty: 'No contract checklists to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total contract checklists)",
			lengthMenu: 'Show _MENU_ contract checklists',
		},
		order: [
			[columnIndicies.vend_name_link, 'ASC'],
			[columnIndicies.contract_title_link, 'ASC'],
			[columnIndicies.surveyresp_date_pretty, 'DESC'],
		],
	};

	const dashboardContractChecklistsDt = $('#dashboard_contract_checklists_table')
		.on('init.dt, draw.dt', ({target}) => {
			$(target).find('.vendor_edit').off('click').on('click', ({target}) => {
				const rowData = getDtRowData(dashboardContractChecklistsDt, target);
				post('/ui/vendor_edit', { id: rowData.vend_id });
			});
			$(target).find('.contract_edit').off('click').on('click', ({target}) => {
				const rowData = getDtRowData(dashboardContractChecklistsDt, target);
				post('/ui/contract', {
					vend_id: rowData.vend_id,
					contract_guid: rowData.contract_guid,
				});
			});
			$(target).find('.assessment').off('click').on('click', ({target}) => {
				const rowData = getDtRowData(dashboardContractChecklistsDt, target);
				post('/ui/assessment', {
					vend_id: rowData.vend_id,
					type: ASSESSMENT_CONCHECKLIST,
					surv_guid: rowData.surveyresp_guid,
				});
			});
		})
		.DataTable(dtOptions);

	$('#dashboard_contract_checklists_filter').off('change').on('change', () => {
		setTimeout(() => dashboardContractChecklistsDt.ajax.reload(), 500);
	});
};

if ($('#dashboard_contract_checklists_table').length == 1) initDashboardContractChecklists();

const getWatchFilters = () => ({
	status: +$('#dashboard_watch_filter_status').val(),
	reason: $('#dashboard_watch_filter_reason').val().map(Number),
	priority: $('#dashboard_watch_filter_priority').val().map(Number),
	removal_reason: $('#dashboard_watch_filter_reason_removed').val().map(Number),
	days: getDateFilter('#dashboard_watch_filter_days'),
	date: getDateFilter('#dashboard_watch_filter_date'),
	removed_date: getDateFilter('#dashboard_watch_filter_removed_date'),
});

let defaultWatchFilters: ReturnType<typeof getWatchFilters>;

const resetWatchFilters = () => {
	$('#dashboard_watch_filter_status').val(defaultWatchFilters.status);
	$('#dashboard_watch_filter_reason').val(defaultWatchFilters.reason);
	$('#dashboard_watch_filter_priority').val(defaultWatchFilters.priority);
	$('#dashboard_watch_filter_reason_removed').val(defaultWatchFilters.removal_reason);
	setDateFilter('#dashboard_watch_filter_days', defaultWatchFilters.days);
	setDateFilter('#dashboard_watch_filter_date', defaultWatchFilters.date);
	setDateFilter('#dashboard_watch_filter_removed_date', defaultWatchFilters.removed_date);
	$('#dashboard_watch_filters select.chosen').trigger('chosen:updated').trigger('change');
};

const initDashboardWatchList = () => {
	defaultWatchFilters = getWatchFilters();

	toggleSlide($('#dashboard_watch_filters_toggle'), $('#dashboard_watch_filters'));

	initDateFilter('#dashboard_watch_filter_days');
	initDateFilter('#dashboard_watch_filter_date');
	initDateFilter('#dashboard_watch_filter_removed_date');

	const dbColumns: DataTables.ColumnSettings[] = [
		{ title: 'Vendor', data: 'vend_name_link', render: null },
		{ title: 'Reason', data: 'reason_title' },
		{ title: 'Priority', data: 'wprior_name' },
		{ title: 'Days on Watch List', data: 'watch_days' },
		{ title: 'Added By', data: 'usr_name' },
		{ title: 'Added On', data: 'watch_date_pretty' },
		{ title: 'Removed By', data: 'usr_remove_name' },
		{ title: 'Removed On', data: 'watch_remove_date_pretty' },
		{ title: 'Reason Removed', data: 'reason_remove_title' },
	];
	const columnIndicies = dtColumnIndicies(dbColumns);
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/dashboard_watch_load',
			data: (postData) => ({
				...postData,
				filters: JSON.stringify(getWatchFilters()),
			}),
		},
		columns: dbColumns,
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) }
		],
		order: [
			[columnIndicies.watch_date_pretty, 'desc'],
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> vendors",
			infoEmpty: 'No vendors to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total vendors)",
			lengthMenu: 'Show _MENU_ vendors',
		},
	};

	const dashboardWatchDt = $('#dashboard_watch_table')
		.on('init.dt, draw.dt', ({target}) => {
			$(target).find('.vendor_edit').off('click').on('click', ({target}) => {
				post('/ui/vendor_edit', { id: target.dataset.vendid });
			});
		})
		.DataTable(dtOptions);

	$('#dashboard_watch_filters select.chosen').chosen({ width: '100%' }).on('change', ({target}) => {
		const $select = $(target);
		const val = $select.val();
		const $chosen = $select.parent().find('.chosen-choices, .chosen-single');
		if (val != -1 && val != '') $chosen.addClass('changed');
		else $chosen.removeClass('changed');
	});

	$('#dashboard_watch_filters').on('change', () => {
		setTimeout(() => dashboardWatchDt.ajax.reload(), 250);
	});

	$('#dashboard_watch_filters_reset').on('click', () => resetWatchFilters());

	$('#dashboard_watch_export').on('click', async () => {
		$('#dashboard_watch_export').prop('disabled', true);

		const filters = getWatchFilters();
		post('/export', {
			type: 'dashboard_watch_list',
			data: JSON.stringify(filters),
		});

		await setTimeoutPromise(1000);
		$('#dashboard_watch_export').prop('disabled', false);
	});

	$('#dashboard_watch_print').on('click', async () => {
		$('#dashboard_watch_print').prop('disabled', true);

		const filters = getWatchFilters();
		const params = {
			...dashboardWatchDt.ajax.params(),
			filters: JSON.stringify(filters),
		};
		post('/printout/watch_list', {params: JSON.stringify(params)}, '_blank');

		await setTimeoutPromise(1000);
		$('#dashboard_watch_print').prop('disabled', false);
	});
};

if ($('#dashboard_watch_table').length == 1) initDashboardWatchList();

//On print window
if ($('#dashboard_watch_printout').length == 1) {
	const deployPrintOut = async () => {
		const params = JSON.parse(atob($('#dashboard_watch_printout').data('params')));

		try {
			params.mode = DATATABLE_ALL;
			const res = await ajaxPromise('/data/dashboard_watch_load', params);
			$('#spinner').show();

			const table = (
				<table className="table table-condensed table-striped" style="width: 100%;">
					<thead>
						<tr>
							{res.headers.map((col) => (
								<th>{col}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{res.data.map((row, index) => (
							<tr className={index % 2 == 0 ? 'odd' : 'even'} style="border-bottom: 1px solid #d0d0d0;">
								{row.map((cell) => (
									<td>{cell != null ? cell : ''}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			);
			$('#dashboard_watch_printout').append(table);

			setTimeout(() => {
				$('#spinner').hide();
				window.print();
			}, 1000);
		}
		catch (error) {
			displayNotification('Watch List', 'There was an error loading the data.', 'danger');
			logerror('watch list get error [ajax]', error);
		}
	};

	deployPrintOut();
}

export var overviewdt: DataTables.Api = null;

if ($('#overview_table').length == 1) {
	init_overview();
}

function init_overview() {
	// "Wireframe"

	var overview_reload_check = false;
	function overview_reload() {
		if (overviewdt !== null && overview_reload_check) {
			overviewdt.ajax.reload();
			overview_reload_check = false;
		}
	}

	$('#inventory_print').off('click');
	$('#inventory_print').on('click', function (e) {
		var params = overviewdt.ajax.params();
		params.filters = getFilters(); // Needed for title to be set on-demand, basically
		params.displaycolor = $('#filter_displaycolor').is(':checked');
		var bad = false;

		//logme(params);

		if (!bad) {
			// Submit the form
			$('#inventory_print').prop('disabled', true);

			post(
				'/printout/inventory',
				{
					params: JSON.stringify(params),
				},
				'_blank'
			);

			setTimeout(function () {
				$('#inventory_print').prop('disabled', false);
			}, 1000);
		}

		e.preventDefault();
	});

	const licenses = {
		inherent: $('#overview_container').data('inherent') != null && $('#overview_container').data('inherent') == '1',
		residual: $('#overview_container').data('residual') != null && $('#overview_container').data('residual') == '1',
		contracts: $('#overview_container').data('contracts') != null && $('#overview_container').data('contracts') == '1',
		vendorvalue: $('#overview_container').data('vendorvalue') != null && $('#overview_container').data('vendorvalue') == '1',
	};

	if ($('#vendor_completion_table').length == 1) {
		const vendorCompletionLink = async () => {
			$('#overview_table').closest('.panel-body-collapse').collapse('show');
			$('#spinner').show();

			await setTimeoutPromise(250);

			$(window).scrollTop($('#overview_table').offset().top - 430);
			$('#spinner').hide();
		};

		$('#vendor_completion_watch_list').on('click', async () => {
			resetWatchFilters();
			$('#dashboard_watch_filter_status').val(WATCH_VENDOR_ACTIVE).trigger('chosen:updated').trigger('change');
			$('#spinner').show();

			await setTimeoutPromise(250);

			$(window).scrollTop($('#dashboard_watch_table').offset().top - 430);
			$('#spinner').hide();
		});

		$('a[data-complete]').on('click', ({target}) => {
			resetFilters();
			$('#vendor_search_complete')
				.val([$(target).data('complete')])
				.trigger('chosen:updated')
				.trigger('change');
			$('#vendor_search_status').val([VENDOR_ACTIVE.toString()]).trigger('chosen:updated').trigger('change'); // Trigger change on last field update
			vendorCompletionLink();
		});
		$('a[data-status]').on('click', ({target}) => {
			resetFilters();
			$('#vendor_search_status')
				.val([$(target).data('status')])
				.trigger('chosen:updated');
			$('#vendor_search_complete').val([]).trigger('chosen:updated').trigger('change'); // Trigger change on last field update
			vendorCompletionLink();
		});
	}

	if ($('#components_completion_table').length == 1) {
		const componentsCompletionLink = () => {
			$('#overview_table').closest('.panel-body-collapse').collapse('show');
			$('#spinner').show();
			setTimeout(() => {
				$(window).scrollTop($('#overview_table').offset().top - 430);
				$('#spinner').hide();
			}, 250);
		};

		$('#components_completion_table a[data-filter]').on('click', ({target}) => {
			$('select.vendor_search_complete_set').val(-1).trigger('chosen:updated');
			const filterNum = +$(target).data('filter');
			if (filterNum === 4) {
				$('#dashboard_services_field_canceldate').val(6).trigger('change');
				$('#spinner').show();
				setTimeout(() => {
					$(window).scrollTop($('#dashboard_contracts_table').offset().top - 430);
					$('#spinner').hide();
				}, 250);
				return;
			}
			const $completionFilter = {
				0: $('#vendor_search_ir_complete'),
				1: $('#vendor_search_dd_complete'),
				2: $('#vendor_search_rd_complete'),
				3: $('#vendor_search_sr_complete'),
			}[filterNum];
			resetFilters();
			$completionFilter.val(0);
			$('#vendor_search_status').val([VENDOR_ACTIVE.toString()]).trigger('chosen:updated');
			$('select.vendor_search_complete_set').trigger('chosen:updated').trigger('change'); // Trigger change on last field update
			componentsCompletionLink();
		});
	}

	if ($('#review_completion_table').length == 1) {
		$('#review_completion_table a[data-filter]').on('click', function () {
			$('#dashboard_vendor_field_nextreview').val($(this).data('filter')).trigger('change');
			$('#spinner').show();
			setTimeout(function () {
				$(window).scrollTop($('#dashboard_vendor_table').offset().top - 430);
				$('#spinner').hide();
			}, 250);
		});
	}

	if ($('#contract_completion_table').length == 1) {
		$('#contract_completion_table a[data-filter]').on('click', function () {
			$('#dashboard_services_field_canceldate').val($(this).data('filter')).trigger('change');
			$('#spinner').show();
			setTimeout(function () {
				$(window).scrollTop($('#dashboard_contracts_table').offset().top - 430);
				$('#spinner').hide();
			}, 250);
		});
	}

	if ($('#documents_completion_table').length == 1) {
		$('#documents_completion_table a[data-filter]').on('click', function () {
			$('#dashboard_documents_field_nextdate').val($(this).data('filter')).trigger('change');
			$('#spinner').show();
			setTimeout(function () {
				$(window).scrollTop($('#dashboard_documents_table').offset().top - 430);
				$('#spinner').hide();
			}, 250);
		});
	}

	if ($('#tasks_completion_table').length == 1) {
		$('#tasks_completion_table a[data-filter]').on('click', function (event) {
			$('#dashboard_tasks_field_status').val($(event.target).data('filter')).trigger('change');
			$('#spinner').show();
			setTimeout(function () {
				$(window).scrollTop($('#dashboard_tasks_table').offset().top - 430);
				$('#spinner').hide();
			}, 250);
		});
	}

	if ($('#watch_completion_table').length == 1) {
		$('#watch_completion_table a[data-filter]').on('click', async ({target}) => {
			$('#dashboard_watch_filter_status').val($(target).data('filter')).trigger('change');
			$('#spinner').show();

			await setTimeoutPromise(250);

			$(window).scrollTop($('#dashboard_watch_table').offset().top - 430);
			$('#spinner').hide();
		});
	}

	if ($('#onboarding_completion_table').length == 1) {
		$('#onboarding_completion_table a[data-filter]').on('click', async ({target}) => {
			$('#dashboard_onboarding_filter').val($(target).data('filter')).trigger('change');
			$('#spinner').show();

			await setTimeoutPromise(250);

			$(window).scrollTop($('#dashboard_onboarding_table').offset().top - 430);
			$('#spinner').hide();
		});
	}

	if ($('#offboarding_completion_table').length == 1) {
		$('#offboarding_completion_table a[data-filter]').on('click', async ({target}) => {
			$('#dashboard_offboarding_filter').val($(target).data('filter')).trigger('change');
			$('#spinner').show();

			await setTimeoutPromise(250);

			$(window).scrollTop($('#dashboard_offboarding_table').offset().top - 430);
			$('#spinner').hide();
		});
	}

	if ($('#contract_checklists_completion_table').length == 1) {
		$('#contract_checklists_completion_table a[data-filter]').on('click', async ({target}) => {
			$('#dashboard_contract_checklists_filter').val($(target).data('filter')).trigger('change');
			$('#spinner').show();

			await setTimeoutPromise(250);

			$(window).scrollTop($('#dashboard_contract_checklists_table').offset().top - 430);
			$('#spinner').hide();
		});
	}

	$('#vendor_inventory_help').popover({
		placement: 'right',
		content: 'Click a row to preview the Vendor below.',
		trigger: 'hover focus',
	});
	/*$('#inventory_filters [data-toggle="popover"]').popover({
		placement: 'top',
		trigger: 'hover focus'
	});*/

	$('#inventory_filters select.chosen').chosen({ width: '100%' });
	$('#inventory_filters select.chosen').on('change', function () {
		let v = $(this).val();
		let $c = $(this).parent().find('.chosen-choices, .chosen-single');
		if (v != -1 && v != '') {
			$c.addClass('changed');
		} else {
			$c.removeClass('changed');
		}
		overview_reload_check = true;
		setTimeout(overview_reload, 250);
	});

	function resetFilters() {
		$('#vendor_search_status').val([VENDOR_ACTIVE.toString(), VENDOR_PENDING.toString()]);
		$('#vendor_search_critical').val(-1);
		$('#vendor_search_ir_complete').val(-1);
		$('#vendor_search_dd_complete').val(-1);
		$('#vendor_search_rd_complete').val(-1);
		$('#vendor_search_sr_complete').val(-1);
		$('#vendor_search_category').val([]);
		$('#vendor_search_inherent').val([]);
		$('#vendor_search_residual').val([]);
		$('#vendor_search_sla').val([]);
		$('#vendor_search_kpi').val([]);
		$('#vendor_search_value').val([]);
		$('#vendor_search_complete').val([]);
		$('#inventory_filters select.chosen').trigger('chosen:updated').trigger('change');
		//Cookies.remove('dashboard_filters');
	}

	$('#filters_reset').on('click', resetFilters);

	function getFilters() {
		var d = {
			category: $('#vendor_search_category').val(),
			status: $('#vendor_search_status').val(),
			critical: $('#vendor_search_critical').val() !== '' ? $('#vendor_search_critical').val() : -1,
			inherent: $('#vendor_search_inherent').val(),
			residual: $('#vendor_search_residual').val(),
			sla: $('#vendor_search_sla').val(),
			kpi: $('#vendor_search_kpi').val(),
			value: $('#vendor_search_value').val(),
			complete: $('#vendor_search_complete').val(),
			ir_complete: $('#vendor_search_ir_complete').val(),
			dd_complete: $('#vendor_search_dd_complete').val(),
			rd_complete: $('#vendor_search_rd_complete').val(),
			sr_complete: $('#vendor_search_sr_complete').val(),
		};
		Cookies.set('dashboard_filters', JSON.stringify(d), { expires: 7 });
		return d;
	}

	var filters = JSON.parse(Cookies.get('dashboard_filters') || null);
	if (filters != null) {
		$('#vendor_search_category').val(filters.category);
		$('#vendor_search_status').val(filters.status);
		$('#vendor_search_critical').val(filters.critical);
		$('#vendor_search_inherent').val(filters.inherent);
		$('#vendor_search_residual').val(filters.residual);
		$('#vendor_search_sla').val(filters.sla);
		$('#vendor_search_kpi').val(filters.kpi);
		$('#vendor_search_value').val(filters.value);
		$('#vendor_search_complete').val(filters.complete);
		$('#vendor_search_ir_complete').val(filters.ir_complete);
		$('#vendor_search_dd_complete').val(filters.dd_complete);
		$('#vendor_search_rd_complete').val(filters.rd_complete);
		$('#vendor_search_sr_complete').val(filters.sr_complete);
		$('#inventory_filters select.chosen').trigger('chosen:updated').trigger('change');
		overview_reload_check = false;
	}

	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/overview_load',
			data: (postData) => ({
				...postData,
				filters: getFilters(),
			}),
			dataSrc: (res) => {
				if (res.hasOwnProperty('charts')) {
					buildCharts(res.charts);
				}
				$('#concentration_refresh').off('click').hide();
				if (res.hasOwnProperty('concentration_risk')) {
					loadConcentrationMap(res.concentration_risk);
					$('#concentration_refresh').show().on('click', (event) => {
						event.stopPropagation();

						$('#concentration_distance_apart').val(null);
						$('#concentration_cluster').prop('checked', false).trigger('change');
						loadConcentrationMap(res.concentration_risk);
					});
				}
				return res.data;
			},
		},
		columns: [
			{
				//0
				title: '',
				data: 'button_open',
				orderable: false,
				searchable: false,
				className: 'nowrap',
				render: null,
			},
			{
				//1
				title: 'Name',
				data: 'vend_name',
				className: 'pointer',
			},
			{
				//2
				title: 'Category',
				data: 'cat_name',
				searchable: false,
				className: 'pointer',
			},
			{
				//3
				title: 'Status',
				data: 'vend_status',
				className: 'pointer',
				searchable: false,
			},
			{
				//4
				title: 'Critical',
				data: 'vend_critical',
				className: 'pointer',
				searchable: false,
				visible: false,
			},
			{
				//5
				title: 'Inherent',
				data: 'inherent_title',
				className: 'pointer',
				searchable: false,
				visible: false,
			},
			{
				//6
				title: 'Residual',
				data: 'residual_title',
				className: 'pointer',
				searchable: false,
				visible: false,
			},
			{
				//7
				title: 'SLA Aggregate',
				data: 'sla_aggregate_title',
				className: 'pointer',
				searchable: false,
				visible: false,
			},
			{
				//8
				title: 'KPI Aggregate',
				data: 'kpi_aggregate_title',
				className: 'pointer',
				searchable: false,
				visible: false,
			},
			{
				//9
				title: 'Value',
				data: 'vendvalue_title',
				className: 'pointer',
				searchable: false,
				visible: false,
			},
			{
				//10
				title: 'Completion',
				data: 'vend_complete',
				className: 'pointer',
				searchable: false,
				visible: false,
			},
		],
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val) }
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> vendors",
			infoEmpty: 'No vendors to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total vendors)",
			lengthMenu: 'Show _MENU_ vendors',
		},
		order: [[1, 'asc']],
		pageLength: 50,
		scrollX: true,
	};

	if (licenses.inherent) {
		dtoptions.columns[4].visible = true;
		dtoptions.columns[5].visible = true;
	}
	if (licenses.residual) {
		dtoptions.columns[6].visible = true;
	}
	if (licenses.contracts) {
		dtoptions.columns[7].visible = true;
		dtoptions.columns[8].visible = true;
	}
	if (licenses.residual && licenses.contracts) {
		dtoptions.columns[10].visible = true;
	}
	if (licenses.vendorvalue) {
		dtoptions.columns[9].visible = true;
	}

	const thresholds = JSON.parse(atob($('#overview_table').data('thresholds')));

	overviewdt = $('#overview_table')
		.on('init.dt, draw.dt', function () {
			var contractsdt: DataTables.Api = null;
			var contactsdt: DataTables.Api = null;

			if ($('#filter_displaycolor').is(':checked')) {
				overviewdt.cells().every(function (ri, ci) {
					switch (ci) {
						case 5: // Inherent
							for (var i = 0; i < thresholds[THRESHOLD_INHERENT].length; i++) {
								if (this.data() == thresholds[THRESHOLD_INHERENT][i].thresh_title) {
									$(this.node()).css({ backgroundColor: thresholds[THRESHOLD_INHERENT][i].thresh_color, color: thresholds[THRESHOLD_INHERENT][i].thresh_contrast });
									break;
								}
							}
							break;
						case 6: // Residual
							for (var i = 0; i < thresholds[THRESHOLD_RESIDUAL].length; i++) {
								if (this.data() == thresholds[THRESHOLD_RESIDUAL][i].thresh_title) {
									$(this.node()).css({ backgroundColor: thresholds[THRESHOLD_RESIDUAL][i].thresh_color, color: thresholds[THRESHOLD_RESIDUAL][i].thresh_contrast });
								}
							}
							break;
						case 7: // SLA
							for (var i = 0; i < thresholds[THRESHOLD_SLA].length; i++) {
								if (this.data() == thresholds[THRESHOLD_SLA][i].thresh_title) {
									$(this.node()).css({ backgroundColor: thresholds[THRESHOLD_SLA][i].thresh_color, color: thresholds[THRESHOLD_SLA][i].thresh_contrast });
								}
							}
							break;
						case 8: // KPI
							for (var i = 0; i < thresholds[THRESHOLD_KPI].length; i++) {
								if (this.data() == thresholds[THRESHOLD_KPI][i].thresh_title) {
									$(this.node()).css({ backgroundColor: thresholds[THRESHOLD_KPI][i].thresh_color, color: thresholds[THRESHOLD_KPI][i].thresh_contrast });
								}
							}
							break;
						case 9: // Vendor Value
							for (var i = 0; i < thresholds[THRESHOLD_VENDORVALUE].length; i++) {
								if (this.data() == thresholds[THRESHOLD_VENDORVALUE][i].thresh_title) {
									$(this.node()).css({ backgroundColor: thresholds[THRESHOLD_VENDORVALUE][i].thresh_color, color: thresholds[THRESHOLD_VENDORVALUE][i].thresh_contrast });
								}
							}
							break;
					}
				});
			}

			const $table = $(this);

			// Load Vendor info into Vendor Preview
			$table
				.find('tbody tr td')
				.off('click')
				.on('click', function () {
					var cellcol = overviewdt.cell($(this)).index().column;

					if (cellcol !== 0) {
						// Button columns should not trigger Vendor Preview
						var $row = $(this).closest('tr');
						var data = overviewdt.row($row).data();

						//logme($table.closest('.dataTables_wrapper').find('table tbody tr'));
						//logme($table.closest('.dataTables_wrapper').find('tbody'));
						// Needed to jump up to the wrapper, find the 2 tables (full & fixed), get the list of tr's for both, THEN highlight the correct row index() via eq()
						$table
							.closest('.dataTables_wrapper')
							.find('tbody')
							.each(function () {
								highlight($(this).find('tr').eq($row.index()));
							});

						$(this).prop('disabled', true);
						setTimeout(function () {
							$(this).prop('disabled', false);
						}, 1500);

						$('#vendor_preview_name').html(htmlEsc(data.vend_name));
						$('#vendor_preview_category').html(htmlEsc(data.cat_name));
						if (licenses.inherent) {
							$('#vendor_preview_critical').show().find('span').html(htmlEsc(data.vend_critical));
							$('#vendor_preview_inherent').show().find('span').html(htmlEsc(data.inherent_title));

							$('#vendor_preview_inherent_link').off('click');
							$('#vendor_preview_inherent_link').on('click', function () {
								post('/ui/vendor_edit', { id: data.vend_id, start: 'inherent' });
							});
						}
						if (licenses.residual) {
							$('#vendor_preview_residual').show().find('span').html(htmlEsc(data.residual_title));

							$('#vendor_preview_residual_link').off('click');
							$('#vendor_preview_residual_link').on('click', function () {
								post('/ui/vendor_edit', { id: data.vend_id, start: 'residual' });
							});
						}
						if (licenses.contracts) {
							$('#vendor_preview_sla').show().find('span').html(htmlEsc(data.sla_aggregate_title));
							$('#vendor_preview_kpi').show().find('span').html(htmlEsc(data.kpi_aggregate_title));

							$('.vendor_preview_contract_link').off('click');
							$('.vendor_preview_contract_link').on('click', function () {
								post('/ui/vendor_edit', { id: data.vend_id, start: 'contracts' });
							});
						}
						if (licenses.vendorvalue) {
							$('#vendor_preview_vendvalue').show().find('span').html(htmlEsc(data.vendvalue_title));

							$('#vendor_preview_vendvalue_link').off('click');
							$('#vendor_preview_vendvalue_link').on('click', function () {
								post('/ui/vendor_edit', { id: data.vend_id, start: 'vendvalue' });
							});
						}

						$('#vendor_preview_edit').off('click');
						$('#vendor_preview_edit').on('click', function () {
							post('/ui/vendor_edit', { id: data.vend_id });
						});

						var preview = JSON.parse(data.preview);
						//logme('preview');
						//logme(preview);
						var addr = [];
						addr.push(preview.detail.address1);
						if (preview.detail.address2 !== null && preview.detail.address2.trim() != '') addr.push(preview.detail.address2);
						if (preview.detail.city !== null && preview.detail.city.trim() != '' && preview.detail.state !== null && preview.detail.state.trim() != '' && preview.detail.postalcode !== null && preview.detail.postalcode.trim() != '') {
							addr.push(preview.detail.city + ', ' + preview.detail.state + ' ' + preview.detail.postalcode);
						}
						if (preview.detail.phone !== null && preview.detail.phone.trim() != '') addr.push('Phone: ' + preview.detail.phone);
						if (preview.detail.mainfax !== null && preview.detail.mainfax.trim() != '') addr.push('Main Fax: ' + preview.detail.mainfax);
						$('#vendor_preview_address').html(addr.map((val) => htmlEsc(val)).join('<br>'));
						if (contractsdt !== null) {
							contractsdt.destroy();
							contractsdt = null;
						}
						let fields = {
							contract_type: CONTRACT_TYPE_STANDARD,
							category: 1,
							status: 1,
							stage: 1,
							importance: 1,
							startdate: 1,
							enddate: 1,
							deadline: 1,
							autorenew: 1,
							sla_latest: 1,
							kpi_latest: 1,
							sla_aggregate: 1,
							kpi_aggregate: 1,
						};
						contractsdt = contractsTableBuild($('#contract_table'), data.vend_id, fields);
						$('#contract_search_archived').off('change');
						$('#contract_search_archived').on('change', function () {
							setTimeout(function () {
								contractsdt.ajax.reload();
							}, 500);
						});
						if (contactsdt !== null) {
							contactsdt.destroy();
							contactsdt = null;
						}
						contactsdt = contactsTableBuild({$target: $('#contacts_table'), vend_id: +data.vend_id, isEditable: false});

						$('#panel-preview').show();
						$('#vendor_preview').collapse('show');

						setTimeout(function () {
							$(window).scrollTop($('#vendor_preview').offset().top - 200);
						}, 400);
					}

					$('#contract_add').off('click');
					$('#contract_add').on('click', function () {
						$(this).prop('disabled', true);
						post('/ui/contract', {
							vend_id: data.vend_id,
							contract_id: null,
						});
						setTimeout(function () {
							$(this).prop('disabled', false);
						}, 1000);
					});
				});

			$(this).find('.vendor_edit').off('click');
			$(this)
				.find('.vendor_edit')
				.on('click', function () {
					var data = JSON.parse(JSON.stringify(overviewdt.row($(this).closest('tr')).data()));
					post('/ui/vendor_edit', { id: data.vend_id });
				});
			$(this).find('.vendor_performance').off('click');
			$(this)
				.find('.vendor_performance')
				.on('click', function () {
					var data = JSON.parse(JSON.stringify(overviewdt.row($(this).closest('tr')).data()));
					post('/ui/vendor_edit', { id: data.vend_id, start: 'performance' });
				});
		})
		.DataTable(dtoptions);

	toggleSlide($('#filters_showhide'), $('#inventory_filters'));

	$('#vendor_search_active, #filter_displaycolor').on('change', function () {
		setTimeout(function () {
			overviewdt.ajax.reload();
		}, 250);
	});
	$('#inventory_export').off('click');
	$('#inventory_export').on('click', function (e) {
		var filters = getFilters();
		var bad = false;

		logme(filters);

		if (!bad) {
			// Submit the form
			$('#inventory_export').prop('disabled', true);
			//$('#vendor_search_spinner').show();

			post('/export', {
				type: 'overview',
				data: JSON.stringify(filters),
			});

			//$("#vendor_search_spinner").hide();
			setTimeout(function () {
				$('#inventory_export').prop('disabled', false);
			}, 1000);
		}

		e.preventDefault();
	});
}

if ($('#inventory_printout').length == 1) {
	const loadPrintout = async () => {
		const params = {
			...JSON.parse(atob($('#inventory_printout').data('params'))),
			length: 10000,
			mode: 1,
		};
		const thresholds = JSON.parse(atob($('#inventory_printout').data('thresholds')));
		try {
			const res = await ajaxPromise('/data/overview_load', params);
			if (res.hasOwnProperty('charts')) {
				buildCharts(res.charts);
			}
			if (res.data.length == 0) throw res;
			$('#spinner').show();

			var table = '<table class="table table-condensed table-striped" style="width:100%;"><thead><tr>';
			for (var i = 0; i < res.columns.length; i++) {
				table += '<th>' + res.columns[i] + '</th>';
			}
			table += '</tr></thead><tbody>';
			for (var i = 0; i < res.recordsFiltered; i++) {
				if (i % 2 == 0) {
					var evenodd = 'odd';
				} else {
					var evenodd = 'even';
				}
				table += '<tr class="' + evenodd + '" style="border-bottom:1px solid #d0d0d0">';
				var row = Object.values(res.data[i]);
				for (var j = 0; j < row.length; j++) {
					var cell = '<td>' + row[j] + '</td>';
					if (params.displaycolor) {
						switch (j) {
							case 4: // Inherent
								for (var k = 0; k < thresholds[THRESHOLD_INHERENT].length; k++) {
									if (row[j] == thresholds[THRESHOLD_INHERENT][k].thresh_title) {
										cell = '<td style="background-color:' + thresholds[THRESHOLD_INHERENT][k].thresh_color + '!important;color:' + thresholds[THRESHOLD_INHERENT][k].thresh_contrast + '!important">' + row[j] + '</td>';
										break;
									}
								}
								break;
							case 5: // Residual
								for (var k = 0; k < thresholds[THRESHOLD_RESIDUAL].length; k++) {
									if (row[j] == thresholds[THRESHOLD_RESIDUAL][k].title) {
										cell = '<td style="background-color:' + thresholds[THRESHOLD_RESIDUAL][k].thresh_color + '!important;color:' + thresholds[THRESHOLD_RESIDUAL][k].thresh_contrast + '!important">' + row[j] + '</td>';
									}
								}
								break;
							case 6: // SLA
								for (var k = 0; k < thresholds[THRESHOLD_SLA].length; k++) {
									if (row[j] == thresholds[THRESHOLD_SLA][k].title) {
										cell = '<td style="background-color:' + thresholds[THRESHOLD_SLA][k].thresh_color + '!important;color:' + thresholds[THRESHOLD_SLA][k].thresh_contrast + '!important">' + row[j] + '</td>';
									}
								}
								break;
							case 7: // KPI
								for (var k = 0; k < thresholds[THRESHOLD_KPI].length; k++) {
									if (row[j] == thresholds[THRESHOLD_KPI][k].thresh_title) {
										cell = '<td style="background-color:' + thresholds[THRESHOLD_KPI][k].thresh_color + '!important;color:' + thresholds[THRESHOLD_KPI][k].thresh_contrast + '!important">' + row[j] + '</td>';
									}
								}
								break;
							case 8: // Vendor Value
								for (var k = 0; k < thresholds[THRESHOLD_VENDORVALUE].length; k++) {
									if (row[j] == thresholds[THRESHOLD_VENDORVALUE][k].thresh_title) {
										cell = '<td style="background-color:' + thresholds[THRESHOLD_VENDORVALUE][k].color + '!important;color:' + thresholds[THRESHOLD_VENDORVALUE][k].thresh_contrast + '!important">' + row[j] + '</td>';
									}
								}
								break;
							default:
								cell = '<td>' + row[j] + '</td>';
								break;
						}
					}
					table += cell;
				}
			}
			table += '</tbody></table>';
			$('#inventory_printout').append(table);
			$('#inventory_printout_count').html(res.recordsFiltered + ' vendors');

			setTimeout(function () {
				$('#spinner').hide();
				window.print();
			}, 1000);
		} catch (error) {
			displayNotification('Report', 'There was an error loading the data.', 'danger');
			logerror('inventory print', error);
		}
	};

	loadPrintout();
}

if ($('#enterprise_printout').length == 1) {
	var charts = JSON.parse(atob($('#enterprise_printout').data('charts')));

	logme(charts);

	buildCharts(charts);

	setTimeout(function () {
		window.print();
	}, 1000);
}

if ($('#contract_enterprise_printout').length == 1) {
	const charts = JSON.parse(atob($('#contract_enterprise_printout').data('charts')));
	buildCharts(charts);
	setTimeout(() => window.print(), 1000);
}

$('#header_showrelationships').on('change', () => {
	if ($('#dashboard_vendor_completion').length == 1) {
		dashboardCompletion();
	}
	if ($('#overview_table').length == 1 && overviewdt !== null) {
		overviewdt.ajax.reload();
	}
	if ($('#dashboard_vendor_table').length == 1 && dashboardVendorDt !== null) {
		dashboardVendorDt.ajax.reload();
	}
	if ($('#dashboard_contracts_table').length == 1 && dashboardContractsDt !== null) {
		dashboardContractsDt.ajax.reload();
	}
	if ($('#dashboard_documents_table').length == 1 && dashboardDocumentsDt !== null) {
		dashboardDocumentsDt.ajax.reload();
	}
});