import { PRIV_MANAGE, TASK_ACTIVE, TASK_ARCHIVED, TASK_OVERDUE, TASK_PROG_CANCELLED, TASK_PROG_COMPLETE, TASK_PROG_IN_PROGRESS, TASK_PROG_NOT_STARTED, TASK_SCHEDULED } from './constants';
import { taskForm } from './main';
import { ajaxPromise, confirmDialog, displayNotification, logerror } from './utils';

const clearReassignModal = () => {
	$('#task_reassign_user_group').show();
	$('#task_reassign_ext_recip_group').hide();
	$('#task_reassign_email_group').hide();
	$('#task_reassign_user,#task_reassign_email,#task_reassign_notes,#task_reassign_duedate,#task_reassign_notify').val('');
	$('#task_reassign_user,#task_reassign_notify').trigger('chosen:updated');
	$('#task_reassign_ext_recip').val('').trigger('chosen:updated');
};

export const clearTaskEditForm = () => {
	$('#task_edit_close').off('click').trigger('reset');
	$('#task_view_vendor').text('');
	$('#task_view_sender').text('');
	$('#task_view_recipients').text('');
	$('#task_view_tasks').html('');
	$('#task_view_senddate').text('');
	$('#task_view_duedate').text('').prop('min', '1000-01-01');
	$('#task_view_notify').text('');
	$('#task_view_status').text('');
	$('#task_view_notes').text('');

	$('#task_edit_col').hide();
	$('#task_edit_form').off('submit');

	$('#task_edit_submit').hide();
	$('#task_edit_reassign').off('click').hide();
	$('#task_edit_cancel').off('click').hide();
	$('#task_edit_expire').off('click').hide();
	$('#task_edit_archive').off('click').hide();
	$('#task_edit_reinstate').off('click').hide();
	$('#task_edit_mark_in_prog').off('click').hide();
	$('#task_edit_mark_complete').off('click').hide();
	$('#task_reassign_form').off('submit');
	$('#task_reassign_user_relowners,#task_reassign_user_admins,#task_reassign_user_managers').empty();
	$('#task_reassign_ext_recip_contacts').off('change').empty();

	clearReassignModal();
};

export const loadTaskEdit = async ({tk_guid, onUpdate = () => {}, mode = 'windowed'}) => {
	const server = await ajaxPromise('/data/task_view', {guid: tk_guid});
	const priv = server.priv;
	const currentDate = new Date().toISOString().split('T')[0];
	const task = server.task;
	const isActionItem = !!task.tk_ttypeid;
	const isOpenTask = [TASK_ACTIVE, TASK_OVERDUE, TASK_SCHEDULED].includes(+task.tk_status);
	const isScheduled = task.tk_status == TASK_SCHEDULED;
	let progress = +task.tk_prog;
	const notes = server.notes;

	$('#task_view_type').text(task.ttype_name);
	$('#task_view_priority').text(task.prior_name);
	$('#task_view_recurring').text(task.tk_recurring_pretty);
	$('#task_view_vendor').text(task.vend_name);
	$('#task_view_sender').text(task.sender);
	$('#task_view_recipients').text(task.recipients);
	$('#task_view_senddate').text(task.tk_senddate_pretty);
	$('#task_view_duedate').text(task.tk_duedate_pretty);
	$('#task_view_notify').text(task.tk_notify_pretty);
	$('#task_view_status').text(task.tk_status_pretty);
	$('#task_view_details').text(task.tk_notes);
	$('#task_view_tasks').text(server.details);

	if (isActionItem) {
		$('#task_view_tasks').parent().hide();
	} else {
		$('#task_view_tasks').parent().show();
	}

	if (task.vend_name) {
		$('#task_view_vendor').parent().show();
	} else {
		$('#task_view_vendor').parent().hide();
	}

	const notesUpdated = () => {
		$('#task_edit_notes_cont').empty();
		notes.forEach((note) => {
			let $note: $;
			if (note.is_self) {
				$note = $(
					<div className="well well-dark well-dark-force" style="margin-bottom: 5px; padding: 10px;">
						<div>
							<i>{note.usr_name}</i>
							{isOpenTask ? (
								<span style="float: right;">
									{note.toggled ? (
										<button className="edit btn btn-success btn-xs">
											<i className="edit fa fa-check me-2"></i>
											Save
										</button>
									) : (
										<button className="edit btn btn-primary btn-xs">
											<i className="edit fa fa-edit me-2"></i>
											Edit
										</button>
									)}
									<button className="delete btn btn-danger btn-xs ms-2">
										<i className="fa fa-trash me-2"></i>
										Delete
									</button>
								</span>
							) : ''}
						</div>
						<div>
							{!!note.toggled
								? (<textarea className="form-control">{note.note_val}</textarea>)
								: (<div>{note.note_val}</div>)
							}
						</div>
					</div>
				);
				$note.find('.edit').on('click', async () => {
					note.toggled = !note.toggled;
					notesUpdated();

					if (!note.toggled) {
						try {
							const postData = {
								type: 'task_note_update',
								json: true,
								data: JSON.stringify({
									tk_guid: task.tk_guid,
									note_guid: note.note_guid,
									note_val: $note.find('textarea').val(),
								}),
							};
							const res = await ajaxPromise('/form/submit', postData);
							if (res.rc !== 'OK') throw res;
							displayNotification('Task Update', 'Note updated.', 'success');
							note.note_guid = res.note_guid;
						}
						catch (error) {
							displayNotification('Task Update', 'An error occurred while updating the task.', 'danger');
							logerror('task_note_update', error);
						}
					}
				});

				$note.find('.delete').on('click', async () => {
					const confirmed = await confirmDialog({
						dialogTitle: 'Delete Note',
						bodyText: 'Are you sure you would like to delete this note?',
						confirmText: 'Delete',
						confirmStyle: 'danger',
					});
					if (!confirmed) return;

					notes.splice(notes.indexOf(note), 1);
					notesUpdated();

					if (!note.note_guid) return;

					try {
						const postData = {
							type: 'task_note_delete',
							json: true,
							data: JSON.stringify({
								tk_guid: task.tk_guid,
								note_guid: note.note_guid,
							}),
						};
						const res = await ajaxPromise('/form/submit', postData);
						if (res.rc !== 'OK') throw res;

						displayNotification('Task Update', 'Note deleted.', 'success');
						note.note_guid = res.note_guid;
					}
					catch (error) {
						displayNotification('Task Update', 'An error occurred while updating the task.', 'danger');
						logerror('task_note_delete', error);
					}
				});

				$note.find('textarea').on('change', () => {
					note.note_val = $note.find('textarea').val();
				});
			} else {
				$note = (
					<div className="well well-dark" style="margin-bottom: 5px; padding: 10px;">
						<div><i>{note.usr_name}</i></div>
						<div>{note.note_val}</div>
					</div>
				);
			}
			$('#task_edit_notes_cont').append($note);
		});
	};

	$('#task_edit_save').off('click').on('click', async () => {
		$('#task_edit_save').prop('disabled', true);

		try {
			const postData = {
				type: 'task_update',
				json: true,
				data: JSON.stringify({
					tk_guid: task.tk_guid,
					tk_prog: +$('#task_edit_prog').val(),
				}),
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;
			displayNotification('Task Update', 'Task updated.', 'success');
		}
		catch (error) {
			displayNotification('Task Update', 'An error occurred while updating the task.', 'danger');
			logerror('task_update', error);
		}

		$('#task_edit_save').prop('disabled', false);
	});

	$('#task_edit_notes_add').off('click').on('click', () => {
		notes.push({
			note_guid: null,
			note_val: '',
			usr_name: 'You, just now',
			is_self: true,
			toggled: true,
		});
		notesUpdated();
	});

	$('#task_edit_notes_add').prop('disabled', !isOpenTask);

	notesUpdated();

	const saveProg = async (prog) => {
		try {
			const postData = {
				type: 'task_mark_prog',
				json: true,
				data: JSON.stringify({
					tk_guid: task.tk_guid,
					tk_prog: prog,
				}),
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;
			displayNotification('Task Update', 'Task updated.', 'success');
			progress = prog;
			progChanged();
		}
		catch (error) {
			displayNotification('Task Update', 'An error occurred while updating the task.', 'danger');
			logerror('task_update', error);
		}
	};

	$('#task_edit_mark_in_prog').off('click').on('click', async () => {
		await saveProg(TASK_PROG_IN_PROGRESS);
	});
	$('#task_edit_mark_complete').off('click').on('click', async () => {
		await saveProg(TASK_PROG_COMPLETE);
	});

	const progChanged = () => {
		$('#task_view_progress').text({
			[TASK_PROG_NOT_STARTED]: 'Not Started',
			[TASK_PROG_IN_PROGRESS]: 'In Progress',
			[TASK_PROG_COMPLETE]: 'Complete',
			[TASK_PROG_CANCELLED]: 'Cancelled',
		}[progress] ?? 'Unknown');
		$('#task_edit_mark_in_prog,#task_edit_mark_complete').hide();
		if (!isScheduled && isActionItem && isOpenTask && progress == TASK_PROG_NOT_STARTED) $('#task_edit_mark_in_prog').show();
		if (!isScheduled && isActionItem && isOpenTask && progress != TASK_PROG_COMPLETE) $('#task_edit_mark_complete').show();
	};

	progChanged();

	$('#task_edit_reassign').prop('disabled', true);

	let showExternalRecipients = false;
	try {
		const res = await ajaxPromise('/data/task_view', {guid: task.tk_guid});
		if (res.rc !== 'OK') throw res;

		$('#task_view_tasks').html(res.desc);

		const recips = res.recips;
		showExternalRecipients = recips.contacts && recips.contacts.length;

		const fillOptgroupWithUserOptions = ($optgroup: $, users) => {
			$optgroup.empty();
			users.forEach((user) => {
				$optgroup.append(
					<option value={user.usr_guid}>{user.usr_firstname} {user.usr_lastname}</option>
				);
			});
			$('#task_reassign_user').trigger('chosen:updated');
		};

		if (recips.relowners && recips.relowners.length) {
			fillOptgroupWithUserOptions($('#task_reassign_user_relowners'), recips.relowners);
		}
		if (recips.admins && recips.admins.length) {
			fillOptgroupWithUserOptions($('#task_reassign_user_admins'), recips.admins);
		}
		if (recips.managers && recips.managers.length) {
			fillOptgroupWithUserOptions($('#task_reassign_user_managers'), recips.managers);
		}
		$('#task_reassign_user').trigger('chosen:updated');

		if (recips.contacts && recips.contacts.length) {
			$('#task_reassign_ext_recip_contacts').empty();
			recips.contacts.forEach((cont) => {
				$('#task_reassign_ext_recip_contacts').append(
					<option value={cont.vcontact_email}>{cont.vcontact_name}</option>
				);
			});
			$('#task_reassign_ext_recip').trigger('chosen:updated');
		}

		$('#task_edit_reassign').prop('disabled', false);
	} catch (error) {
		displayNotification('Task View', 'Could not view task details.', 'danger');
		return;
	}

	if (priv == PRIV_MANAGE && isOpenTask) {
		if (isActionItem) {
			$('#task_edit_add').show();
			$('#task_edit_submit').show();
		}
		else {
			$('#task_edit_submit').hide();
			$('#task_edit_add').hide();
		}

		$('#task_edit_cancel').show().on('click', (event) => {
			event.preventDefault();

			const cancel = async (options = {update_repeating: 0}) => {
				try {
					const postData = {
						type: 'task_cancel',
						data: { guid: task.tk_guid, ...options },
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Task Cancel', 'The task has been cancelled.', 'success');
					if (mode === 'main') window.location.reload();
					onUpdate();
					return true;
				} catch (event) {
					displayNotification('Task Cancel', 'There was an error while cancelling this task.', 'danger');
					logerror('task cancel', event);
					return false;
				}
			};

			$('#task_save_msg').text('Are you sure you would like to cancel this task?');
			$('#task_save_update_repeating_text').text(' Cancel future scheduled Action Items');
			$('#task_save_update_repeating').prop('checked', false);
			const showUpdateRepeating = task && +task.tk_recurring && (+task.tk_latest || task.tk_status == TASK_SCHEDULED);
			if (showUpdateRepeating) $('#task_save_update_repeating_cont').show();
			else $('#task_save_update_repeating_cont').hide();
			$('#task_save_confirm').text('Confirm').off('click').on('click', async () => {
				$('#task_save_confirm').prop('disabled', true);
				const update_repeating = showUpdateRepeating ? ($('#task_save_update_repeating').is(':checked') ? 1 : 0) : 0;
				const success = await cancel({update_repeating});
				$('#task_save_confirm').prop('disabled', false);
				if (success) $('#task_save_modal').modal('hide');
			});

			$('#task_save_modal').modal('show');
		});

		$('#task_edit_expire').show().on('click', (event) => {
			event.preventDefault();

			const expire = async (options = {update_repeating: 0}) => {
				try {
					const postData = {
						type: 'task_expire',
						data: { guid: task.tk_guid, ...options },
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Task Expire', 'The task has been set to expired.', 'success');
					if (mode === 'main') window.location.reload();
					onUpdate();
					return true;
				} catch (event) {
					displayNotification('Task Expire', 'There was an error while setting the task as expired.', 'danger');
					logerror('task expire', event);
					return false;
				}
			};

			$('#task_save_msg').text('Are you sure you would like to set this task as expired?');
			$('#task_save_update_repeating_text').text(' Expire future scheduled Action Items');
			$('#task_save_update_repeating').prop('checked', false);
			const showUpdateRepeating = task && +task.tk_recurring && (+task.tk_latest || task.tk_status == TASK_SCHEDULED);
			if (showUpdateRepeating) $('#task_save_update_repeating_cont').show();
			else $('#task_save_update_repeating_cont').hide();
			$('#task_save_confirm').text('Expire').off('click').on('click', async () => {
				$('#task_save_confirm').prop('disabled', true);
				const update_repeating = showUpdateRepeating ? ($('#task_save_update_repeating').is(':checked') ? 1 : 0) : 0;
				const success = await expire({update_repeating});
				$('#task_save_confirm').prop('disabled', false);
				if (success) $('#task_save_modal').modal('hide');
			});

			$('#task_save_modal').modal('show');
		});

		$('#task_edit_reassign').show().on('click', (event) => {
			event.preventDefault();
			$('#task_reassign_duedate').val(task.tk_duedate < currentDate ? currentDate : task.tk_duedate);
			$('#task_reassign_notes').val(task.tk_notes);
			$('#task_reassign_notify').val(task.tk_notify).trigger('chosen:updated');
			$('#task_reassign_modal').modal('show');
		});

		$('#task_reassign_ext_recip').off('change').on('change', ({target}) => {
			const isCustom = !$(target).val();
			if (isCustom) $('#task_reassign_email_group').show();
			else $('#task_reassign_email_group').hide();
		});

		$('#task_reassign_form').on('submit', async (event) => {
			event.preventDefault();
			const postData = {
				guid: task.tk_guid,
				usr_guids: $('#task_reassign_user').val() as string[],
				email: $('#task_reassign_ext_recip').val() || $('#task_reassign_email').val(),
				duedate: $('#task_reassign_duedate').val(),
				notes: $('#task_reassign_notes').val(),
				notify: $('#task_reassign_notify').val(),
			};
			let bad = false;
			if (!postData.duedate) {
				displayNotification('Task Reassign', 'Please specify a due date.', 'danger');
				bad = true;
			}
			if (postData.duedate && postData.duedate < currentDate) {
				displayNotification('Task Reassign', 'You cannot create a task in the past.', 'danger');
				bad = true;
			}
			if (bad) return;

			$('#task_reassign_submit').prop('disabled', true);
			try {
				const res = await ajaxPromise('/form/submit', {type: 'task_reassign', data: postData});
				if (res.rc !== 'OK') throw res;

				displayNotification('Task Reassign', 'The task has been reassigned.', 'success');
				onUpdate();
				$('#task_reassign_modal').modal('hide');
			}
			catch (error) {
				switch (error.rc) {
					case 'INVALID_EXTERNAL_EMAIL':
						displayNotification('Task Reassign', 'Please enter a valid external email address.  Multiple external email addresses are not allowed.', 'danger');
						break;
					default:
						displayNotification('Task Reassign', 'Could not reassign the task.', 'danger');
						logerror('task reassign', error);
				}
			}

			$('#task_reassign_submit').prop('disabled', false);
		});

		taskForm({$cont: $('#task_edit_add'), $submit: $('#task_edit_submit'), recipientView: mode === 'main', task, onCreate: () => onUpdate()});
	} else {
		$('#task_edit_add').hide();
		$('#task_edit_submit').hide();

		if (task.tk_status == TASK_ARCHIVED) {
			$('#task_edit_reinstate').show().on('click', async (event) => {
				event.preventDefault();

				try {
					const postData = {
						type: 'task_reinstate',
						data: { guid: task.tk_guid },
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Task Reinstate', 'The task has been reinstated.', 'success');
					if (mode === 'main') window.location.reload();
					onUpdate();
				} catch (event) {
					displayNotification('Task Reinstate', 'There was an error while reinstating the task.', 'danger');
					logerror('task reinstate', event);
				}
			});
		} else {
			$('#task_edit_archive').show().on('click', async (event) => {
				event.preventDefault();

				try {
					const postData = {
						type: 'task_archive',
						data: { guid: task.tk_guid },
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Task Archive', 'The task has been archived.', 'success');
					if (mode === 'main') window.location.reload();
					onUpdate();
				} catch (event) {
					displayNotification('Task Archive', 'There was an error while archiving the task.', 'danger');
					logerror('task archive', event);
				}
			});
		}
	}
};

$('#task_reassign_modal').on('hidden.bs.modal', () => clearReassignModal());

$('#task_reassign_user,#task_reassign_notify,#task_reassign_ext_recip').chosen({ width: '100%' });

if ($('#task_edit_cont').data('guid')) {
	loadTaskEdit({tk_guid: $('#task_edit_cont').data('guid'), mode: 'main'});
}